/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */
import {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from "react";
import { ThemeProvider as ThemeProviderStyled } from "styled-components";
import { MasterContext } from "./MasterContext";
import UserService from "api/users";
import SettingService from "api/settings";
import { defaultTheme } from "helper/context";
import get from "lodash.get";

type ITheme = {
  themesSetting?: any;
  defaultTheme: any;
  userTheme?: any;

  theme?: string;
  themeColors?: object;
  logos?: any;
  handleSwitchTheme?: () => void;
  getThemesSetting: (type: any) => void;
  setThemesSetting: (item: any) => void;
  getThemeByKey: (key: string) => any;
};

export const ThemeContext = createContext<ITheme>({} as ITheme);

const ThemesColors = {
  // white100: themesSetting.dark.text.color,
  // orange100: "#FF9900",
  // green100: "#00AC11",
  // bgColorLayer1: themesSetting.dark.background.background_color,
  // bgColorLayer2: themesSetting.dark.background.background_color,
  // black100: themesSetting.dark.background.background_color,
  // blue200: "#0d203e",
  // blue300: "#172D4D",
  // grey100: "#a0a0a0",
  // grey200: "#3A3F51",
  // red100: "#dc3545",
  // yellow100: "#FFE600",
};

const ThemesColorsHover = {
  white100: "#cecbcb",
  orange100: "#ff8800d8",
  red100: "#b81727",
  green100: "#00ac11a9",
  blue100: "#2465e8a9",
};

const ThemeProvider = (props: any) => {
  const { auth } = useContext(MasterContext);

  const _theme = useMemo(() => localStorage.getItem("theme"), []);
  const [theme, setTheme] = useState(_theme || "light");
  const [themesSetting, setThemesSetting] = useState<any>(defaultTheme[theme]);
  const [logos, setLogos] = useState();

  const updateUserTheme = useCallback(async (req: any) => {
    try {
      await UserService.editUser(req);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const getThemesSetting = useCallback(async (type: string) => {
    try {
      const res: any = await SettingService.getSettingByName("themes");
      if (res.setting && res.setting.value) {
        setThemesSetting({
          ...defaultTheme[type],
          ...res.setting.value[type],
        });
      } else {
        setThemesSetting(defaultTheme[type]);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const handleSwitchTheme = useCallback(() => {
    const newTheme = theme === "dark" ? "light" : "dark";
    localStorage.setItem("theme", newTheme);
    setTheme(newTheme);
    setThemesSetting(defaultTheme[newTheme]);
  }, [theme]);

  const getLogos = useCallback(async () => {
    try {
      const res: any = await SettingService.getSettingByName("logos");
      setLogos(res?.setting?.value);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const getThemeByKey = useCallback(
    (key: string) => get(themesSetting, key),
    [themesSetting]
  );

  useEffect(() => {
    if (auth) {
      updateUserTheme({
        params: {
          id: auth?._id,
        },
        body: {
          theme: theme,
        },
      });
    }
  }, [auth, theme, updateUserTheme]);

  useEffect(() => {
    if (_theme) {
      setTheme(_theme);
      getThemesSetting(_theme);
    }
  }, [_theme, getThemesSetting]);

  useEffect(() => {
    getLogos();
  }, [getLogos]);

  const contextValue = useMemo(
    () => ({
      theme,
      themesSetting,
      defaultTheme,
      logos,
      handleSwitchTheme,
      setThemesSetting,
      getThemesSetting,
      getThemeByKey,
    }),
    [
      theme,
      themesSetting,
      logos,
      handleSwitchTheme,
      getThemesSetting,
      getThemeByKey,
    ]
  );

  return (
    <ThemeContext.Provider value={contextValue}>
      <ThemeProviderStyled
        theme={{ colors: ThemesColors, hoverColors: ThemesColorsHover }}
      >
        {props.children}
      </ThemeProviderStyled>
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
